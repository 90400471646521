.administration-tile {
    border-radius: 8px;
    border: 1px solid #f9f9f9;
    box-shadow: 0 3px 6px #677381;
    padding: 0;
    aspect-ratio: 1 / 1;
}

.administration-tile .title {
    font-size: 20px;
    font-weight: bold;
    color: #707070;
    text-transform: uppercase;
    padding: 12px 24px;
    border-bottom: 1px solid #c6c6c6;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.administration-tile .title:hover {
    text-decoration: underline;
}

.administration-tile .content {
    width: 100%;
    padding: 12px 24px;
    overflow: auto;
    aspect-ratio: 1 / 1;
}

.administration-tile .content::-webkit-scrollbar {
    width: 8px;
}

.administration-tile .content::-webkit-scrollbar-track {
    display: none;
}

.administration-tile .content::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: rgba(0,0,0,0.18);
}

.administration-tile .content::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0,0,0,0.25);
}