.administration-modules-sidebar {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    border-right: 1px solid #c6c6c6;
}

.administration-modules-sidebar h3 {
    font-weight: bold;
    color: #707070;
    text-transform: uppercase;
}

.administration-modules-sidebar .sidebar-item {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 8px;
    cursor: pointer;
}

.administration-modules-sidebar .sidebar-item:hover {
    text-decoration: underline;
}