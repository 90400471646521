.received-messages-icon {
    position: fixed;
    bottom: 60px;
    right: 30px;
    color: #0099ff;
    font-size: 40px;
    opacity: 0.15;
    height: 40px;
}

.received-messages-icon.unread-messages, .received-messages-icon:hover {
    opacity: 1;
}

.received-messages-icon>.message-count {
    position: absolute;
    top: 4px;
    right: -5px;
    font-size: 16px;
    color: #fff;
    background-color: #F05B59;
    min-width: 20px;
    border-radius: 10px;
    padding-left: 6px;
    padding-right: 5px;
    font-weight: bold;
    font-size: 14px;
    display: none;
}

.received-messages-icon>.filter-icon {
    bottom: 1px;
    left: 14px;
    color: #fff;
    position: absolute;
    font-size: 14px;
    opacity: 0.7;
}

.received-messages-icon.unread-messages>.message-count {
    display: block;
}

.received-message-row {
    padding: 5px 5px 10px 5px;
}

.received-message-header, .received-message-content {
    display: flex;
    justify-content: space-between;
    padding-top: 5px;
}

.received-message-header>* {
    flex: 1;
}

.received-message-header .timestamp {
    font-size: 8px;
    text-transform: uppercase;
    vertical-align: text-bottom;
    margin-left: 7px;
    display: inline;
}

.received-message-header>:first-child {
    flex: 1;
}

.received-message-header>:last-child {
    text-align: right;
}

.received-message-content .unread-message {
    font-weight: bold;
}

.received-message-row:nth-child(2n) {
    background-color: #f9f9f9;
}

.received-messages-modal .modal-header {
    border-bottom: 1px solid rgba(198, 198, 198, 0.5);
    align-items: center;
}

.received-messages-modal .modal-footer {
    border-top: 1px solid rgba(198, 198, 198, 0.5);
    justify-content: space-between;
}

.received-messages-modal .modal-dialog {
    min-width: 900px;
}

.received-message-header a, .received-message-content .cursor-pointer {
    color: #707070;
}

.received-message-header .mobile{
    font-size: 12px;
}

.received-message-header .toggle-message-read-button {
    color: #707070;
    margin-left: 5px;
}

.received-messages-paging {
    flex-grow: 1;
    text-align: left;
    display: flex;
    justify-content: space-between; 
}

.received-messages-paging > * {
    margin: 0;
}

.received-messages-filter {    
    display: flex;
}

.received-messages-filter > *:not(:last-child) {
    margin-right: 8px;
}

.received-messages-filter #dropdown-input-field-primaryLanguage {
    min-width: 186px;
    height: 36px;
}

.received-messages-filter .multiselect {
    border-radius: 0.25rem;
    border: 1px solid #ced4da;
    font-weight: bold;
    padding-top: 2px;
}

.received-messages-filter .multiselect .form-control {
    color: #333333;
    padding-top: 0;
    padding-bottom: 0;
}

.received-messages-filter .multiselect .multiselect-item {
    background-color: #707070;
    color: #ffffff;
}

.received-messages-filter .multiselect .multiselect-item button {
    color: #ffffff;
}

.unknown-sender {
    color: #F05B59;
}