.loan-picker {
    display: flex;
    justify-content: flex-end;
    position: relative;
    flex: 1;
    margin-right: -30px;
    min-width: 159px;
}

.loan-picker .loan-picker-item {
    font-family: 'Nunito Sans';
    font-weight: 900;
    font-size: 12px;
    margin: 1px;
    cursor: pointer;
}

.loan-picker .loan-picker-item .status {
    height: 33px;
    line-height: 33px;
    font-size: 10px;
}

.loan-picker .loan-picker-item .status svg {
    padding-right: 7px;
    vertical-align: middle;
    margin-bottom: 1px;
}

.loan-picker .loan-picker-item .date-container {
    color: #9EA8B2;
    background-color: #FFFFFF;
    height: 26px;
    line-height: 26px;
    text-transform: uppercase;
    transition: background-color 300ms ease-out;
}

.loan-picker .loan-picker-item:hover .date-container, .loan-picker .loan-picker-item.selected .date-container {
    background-color: #E7EBEE;
    transition: background-color 300ms ease-in;
}

.loan-picker .scroll-button {
    width: 25px;
    padding-left: 5px;
    padding-right: 5px;
    margin: 2px;
}

.loan-picker .loan-picker-item.hidden {
    opacity: 0.65;
}

.loan-picker .history-filter>* {
    position: absolute;
    top: -22px;
    right: 42px;
    font-size: 14px;    
    color: #c6c6c6;
    cursor: pointer;
}

.loan-picker .history-filter .selected {
    color: #707070;
}