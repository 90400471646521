.send-message-template-post-action svg {
    margin-right: 8px;
}

.change-status-template-post-action .status {
    border-radius: 15px;
    padding: 2px 10px 1px;
    font-weight: 900;
    font-size: 12px;
}

.change-status-template-post-action .content {
    display: flex;
    column-gap: 8px;
    align-items: center;
}