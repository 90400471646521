@import './styles/button.css';
@import './styles/column-card.css';
@import './styles/datepicker.css';
@import './styles/dropdown.css';
@import './styles/forms.css';
@import './styles/modal.css';
@import './styles/navigation.css';
@import './styles/overlay.css';
@import './styles/popover.css';
@import './styles/table.css';
@import './styles/toast.css';
@import './styles/loan-status.css';

html, body {
	margin: 0;
	padding: 0;
	font-family: "Nunito Sans";
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	height: 100%;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

#root {
	height: 100%;
	display: flex;
	flex-direction: column;
}

.jumbotron {
	box-shadow: 0px 3px 6px #677381;
	margin: 19px auto 14px;
	position: relative;
}

.cursor-pointer {
	cursor: pointer;
}

.disabled {
	opacity: 0.65;
}

.color-white {
	color: white;
}

.uppercase {
	text-transform: uppercase;
}

.light-on-hover {
	border-radius: 5px;
	padding: 5px;
	transition: ease-out;
	transition-duration: 300ms;
}

.light-on-hover:hover {
	transition: ease-in;
	transition-duration: 300ms;
	background-color: rgba(255, 255, 255, 0.25);
}

.main-content {
	width: 100%;
	background-color: #677381;
	padding-left: 35px;
	padding-right: 35px;
	overflow: auto;
	flex: 1;
}

.main-content.readonly > * {
	pointer-events: none;
}

.description {
	font-weight: 600;
	font-size: 10px;
	color: #c6c6c6;
	text-transform: uppercase;
	text-align: left;
}

.parent-application {
	cursor: pointer;
}

.parent-application:hover {
	text-decoration: underline;
}

.under-construction {
	display: flex;
	flex-grow: 12;
	justify-content: center;
	align-items: center;
	width: 100%;
}

svg {
	box-sizing: border-box !important;
}

@media (max-width: 575.98px) {
	.topbar {
		padding-right: 10px;
	}

	.topbar-buttons {
		padding: 0;
	}
}