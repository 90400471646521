.administration {
    padding: 32px;
}

.module-view {
    display: grid;
    grid-template-columns: minmax(200px, 20%) 1fr;
    gap: 32px;
}

.settings-table {
    display: table;
    margin-left:auto;
    margin-right:auto;
    max-width: 45%;
}

.settings-table>div {
    display: table-row;    
}

.settings-table>div:nth-of-type(2n + 1) {
    background-color: #f9f9f9;
}

.settings-table>div>div {
    display: table-cell;
    text-align: left;
    padding: 10px;
}

.settings-table>div>div:first-child {    
    width: 200px;
}

.settings-table>div>div:last-child {
    text-align: right;
    width: 80px;
}

.settings-table>div>div:last-child > span {
    margin-left: 5px;
}

.settings-table input[type=text] {
    width: 120px;
}

.settings-table .input-group-text {
    padding: 0 6px 0 6px
}

.color-picker input[type=color]{
    width: 35px;
}

.color-picker input[type=text]{
    max-width: 80px;
}