.applications-pipeline-view .toolbar {
    position: absolute;
    top: 0;
    right: 0;
    width: auto;
}

.applications-pipeline-view .bookmarks {
    margin-top: 4px;
}

.applications-pipeline-view .generic-dropdown #pipelines {
    width: 400px;
    font-size: 24px;
    justify-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-color: transparent;
    gap: 8px;
}

.applications-pipeline-view .generic-dropdown #pipelines svg {
    float: none;
}

.applications-pipeline-view .generic-dropdown #pipelines-menu {
    width: 400px;
    margin-top: 8px;
}