.status.status-gray {
    color: #9EA8B2;
    background-color: #EEEEEE;
}

.status.status-blue {
    background-color: #559AF4;
}

.status.status-orange {
    background-color: #FF9900;
}

.status.status-turquoise {
    background-color: #5BC0DE;
}

.status.status-green {
    background-color: #42D253;
}

.status.status-red {
    background-color: #F05B59;
}

.status.status-lightgoldenrodyellow {
    color: #9EA8B2;
    background-color: #FAFAD2;
}

.status.status-moccasin {
    color: #9EA8B2;
    background-color: #FFE4B5;
}