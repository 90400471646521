.calendar-view .modal-dialog {
    max-width: fit-content;
}

.calendar-view .modal-body {
    padding: 0;
    margin-bottom: -7px; /* modal leaves some wierd spacing at the bottom - ugly fix */
}

.calendar-view .modal-content {
    overflow: hidden;
}

.calendar-view .react-datepicker__current-month {
    font-size: 24px;
    line-height: 64px;
}

.calendar-view .react-datepicker__day-names {
    display: flex;
    justify-content: space-around;
}

.calendar-view .react-datepicker__day-name {
    width: 100px;
    font-size: 16px;
}

.calendar-view .react-datepicker__navigation {
    width: 64px;
    height: 64px;
}

.calendar-view .react-datepicker__navigation-icon::before {    
    width: 16px;
    height: 16px;
    border-width: 4px 4px 0 0;
    top: calc(50% - 2px);
}

.calendar-view .react-datepicker__navigation-icon--previous::before {
    right: -16px;
}

.calendar-view .react-datepicker__navigation-icon--next::before {
    left: -16px;
}

.calendar-view .react-datepicker__month {
    margin: 0;
}

.calendar-view .react-datepicker__week {
    display: flex;
}

.calendar-view .react-datepicker__week:not(:last-child) .react-datepicker__day {
    border-bottom: 1px solid #aeaeae;
}

.calendar-view .react-datepicker__day {
    margin: 0;
    width: auto;
    border-radius: 0;
    cursor: default;
}

.calendar-view .react-datepicker__day:hover {
    background: initial;
}

.calendar-view .react-datepicker__day:not(:last-child) {
    border-right: 1px solid #aeaeae;
}

.calendar-view .react-datepicker__day--keyboard-selected {
    background: initial !important;
    color: initial !important;
}

.calendar-view .react-datepicker__day--today {
    color: #3490dc !important;
    font-weight: bold;    
    box-shadow: inset 0 4px 0 0 #3490dc;
}

.calendar-view .out-of-month {
    color: #c6c6c6 !important;
}

.calendar-view .daily-follow-ups {
    width: 150px;
    height: 120px;
    text-align: right;
    padding: 8px;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
}

.calendar-view .status {
    margin-left: -8px;
    border-radius: 0 5px 5px 0;
    text-align: left;
    padding: 0 8px;
    cursor: pointer;
    font-size: 12px;
    font-weight: bold;
}
.calendar-view .status:hover {
    text-decoration: underline;
}

