.generic-dropdown {
    border-radius: 0.25rem;
}

.generic-dropdown svg {
    float:right;
    margin-top: 1px;
    color:#707070;
    margin-left: 8px;
}

.generic-dropdown button.dropdown-toggle::after {
    display: none;
}

.generic-dropdown .btn.dropdown-toggle,
.generic-dropdown .btn.dropdown-toggle:active,
.generic-dropdown .btn.dropdown-toggle:focus,
.generic-dropdown.show .btn.dropdown-toggle {
    width: 100%;
    height: 32px;
    line-height: 17px;
    background-color: inherit;
    border-color: #ced4da;
    color: #333333;
    font-size: 14px;
    text-align: left;
    box-shadow: none;
}

.generic-dropdown .btn.dropdown-toggle.is-invalid {
border-color: #dc3545;
}

.generic-dropdown .dropdown-menu {
    width: 100%;
    font-size: 14px;
    max-height: 456px;
    overflow: auto;
}

.generic-dropdown .dropdown-menu a {
    padding-left: 0.75rem;
}

.generic-dropdown .dropdown-menu hr {
    margin-top: 3px;
    margin-bottom: 3px;
}

.generic-dropdown .dropdown-menu input.dropdown-input {
    padding-left: 0.75rem;
    border: none;
    line-height: 25px;
    display: block;
    width: 100%
}

.generic-dropdown .dropdown-menu input.dropdown-input:focus {
    outline: none;
}

/* these selectors are not grouped because if any one of them fails it will not be applied */
.generic-dropdown .dropdown-menu input.dropdown-input::placeholder {
   font-style: italic;     
}
.generic-dropdown .dropdown-menu input.dropdown-input::-webkit-input-placeholder {
    font-style: italic;
}
.generic-dropdown .dropdown-menu input.dropdown-input:-moz-placeholder {
    font-style: italic;  
}
.generic-dropdown .dropdown-menu input.dropdown-input::-moz-placeholder {
    font-style: italic;  
}
.generic-dropdown .dropdown-menu input.dropdown-input:-ms-input-placeholder {  
    font-style: italic; 
}

.generic-dropdown .dropdown-item {
    min-height: 21px;
}