.pipeline {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.pipeline-filters {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    gap: 12px;
    border-bottom: 1px solid #c6c6c6;
}

.stages {
    display: flex;
    overflow-x: auto;
}

.stages::-webkit-scrollbar {
    height: 8px;
}

.stages::-webkit-scrollbar-track {
    display: none;
}

.stages::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: rgba(0,0,0,0.18);
}

.stages::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0,0,0,0.25);
}

.stages .stage {
    flex: 1 1 100%;
    border: 1px solid #c6c6c6;
    border-left: none;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.stages .stage:first-child {
    border-left: 1px solid #c6c6c6 !important;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.stages .stage:not(:has(+ .stage)) {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.stages .stage .title {
    padding: 12px 8px;
}

.stages .stage .title .sorting .icon {
    cursor: pointer;
}

.stages .stage .title .sorting .icon.disabled {
    cursor: default;
}

.stages .stage .title .sorting .dropdown-arrow-down {
    display: none !important;
}

.stages .stage .title .sorting .dropdown-item.active,
.stages .stage .title .sorting .dropdown-item:active {
    background: #e7ebee;
    color: #212529;
}

.stages .stage .items {
    padding: 12px 8px 12px 8px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    flex-grow: 1;
    overflow-y: auto;
    min-height: 400px;
}

.stages .stage .items::-webkit-scrollbar {
    width: 8px;
}

.stages .stage .items::-webkit-scrollbar-track {
    display: none;
}

.stages .stage .items::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: rgba(0,0,0,0.18);
}

.stages .stage .items::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0,0,0,0.25);
}

.stages .stage .items .load-more-data {
    font-size: 12px;
    padding: 0;
}

.stages .stage .summary:empty {
    display: none;
}

.stages .stage .summary {
    padding: 12px 8px;
    background: #e7ebee;
}
