.applicants-view-picker {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.applicants-view-picker .btn {
    color: #808080;
    border: none;
}

.applicants-view-picker .btn.active {
    color: #559AF4;
}