.dashboard {
    --max-columns: 3;
    --max-tile-size: 500px;
    --max-gaps: 2;
    --gap: 32px;
    --tile-width: minmax(calc((100% - var(--max-gaps) * var(--gap)) / var(--max-columns)), var(--max-tile-size));
    display: grid;
    grid-template-columns: repeat(auto-fill, var(--tile-width));
    gap: var(--gap);
    justify-content: center;
}