.logs-and-comments {
    font-size: 14px;
    padding-bottom: 16px;
}

tr:not(.expandable) .limited-text button {
    display: none;
}

.limited-text {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
}

.limited-text .button-wrapper {
    display: flex;
    align-items: center;
    column-gap: 8px;
}

.limited-text button {
    font-size: 12px;
    padding: 0;
}

.limited-text > span {
    white-space: pre-wrap;
    display: -webkit-box !important;
}

.limited-text .show-more,
.limited-text.show .show-less {
    display: block;
}

.limited-text .show-less,
.limited-text.show .show-more {
    display: none;
}

.limited-text:not(.show) > span {
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.logs-and-comments svg {
    color: #707070;
}

.logs-and-comments .card-heading {
    margin-bottom: 12px;
}

.logs-and-comments textarea {
    margin-top: 10px;
    height: 114px;
}

.logs-and-comments textarea:not(:placeholder-shown) {
    border: 1px solid #34A1FD;
}

.logs-and-comments-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}

.logs-and-comments-buttons>span {
    word-break: break-word;
    white-space: pre-wrap;
    padding-right: 5px;
}

.logs-and-comments-filter {
    display: flex;
    justify-content: space-between;
    margin-top: 13px;
}

.notification-selection {    
    margin-top: 13px;
}

.notification-selection > div {
    display: flex;
    justify-content: space-between;
}

.notification-selection > div:first-child {
    margin-bottom: 10px;
    font-weight: bold;
}

.span-with-checkbox>svg.svg-inline--fa {
    border: 1px solid #707070;
    border-radius: 3px;
    font-size: 17px;
    color: white;
    width: 17px;
}

.span-with-checkbox.checked>svg.svg-inline--fa {
    background-color: #3490DC;
}

.span-with-checkbox>span {
    margin-left: 10px;
}

table.logs-and-comments-table {
    width: 100%;
    height: 452px;
    text-align: left;
    margin-top: 21px;
    margin-bottom: 15px;
}

table.logs-and-comments-table thead tr {
    background-color: #F2F5F7;
    text-transform: uppercase;
    color: #9EA8B2;
    font-size: 12px;
}

table.logs-and-comments-table thead tr th:first-child {
    padding: 12px 0 12px 13px;
}

table.logs-and-comments-table thead tr th:last-child {
    width: 95px;
}

table.logs-and-comments-table tbody tr td {
    height: 41px;
    position: relative;
    padding: 4px;
}

table.logs-and-comments-table tbody tr td:last-child {
    white-space: nowrap;
}

table.logs-and-comments-table tbody tr td:first-child {
    width: 40px;
    min-width: 40px;
}

table.logs-and-comments-table tbody tr td:first-child:empty {
    display: table-cell;
}

table.logs-and-comments-table tbody tr td:first-child img {
	margin-left: 2px;
}


table.logs-and-comments-table tbody tr td:first-child svg {
    display: block;
    font-size: 17px;
}

table.logs-and-comments-table tbody tr:nth-child(even) {
    background-color: #F9F9F9;
}

table.logs-and-comments-table td.messaging-result {
    padding-top: 5px;
    padding-bottom: 5px;
}

table.logs-and-comments-table td .customer-communication-type-icon,
table.logs-and-comments-table td .comment-icon,
table.logs-and-comments-table td .event-log-icon,
table.logs-and-comments-table td .change-log-icon {
    display: flex;
    justify-content: center;
    position: relative;
}

table.logs-and-comments-table td .customer-communication-icons {
    display: flex;
    column-gap: 4px;
    justify-content: flex-end;
    margin-right: 4px;
}

table.logs-and-comments-table tr:not(.expandable) .customer-communication-icons {
    position: absolute;
    bottom: 4px;
    right: 0;
}

table.logs-and-comments-table .message {
    width: 100%;
    display: block;
}

table.logs-and-comments-table .message.email {
    cursor: pointer;
}

table.logs-and-comments-table .unread-message {
    font-weight: bold;
}

.template-post-actions {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    text-align: left;
}

.template-post-actions > span {
    margin-top: 10px;
}

.template-post-actions > span:only-child {
    display: none;
}

div.comment-box {
	display: flex;
    align-items: center;
    background-color: #559AF4;
    color: #FFFFFF;
    padding: 5px 10px 5px 0;
    margin: 5px 10px 5px 10px;
	border-radius: 5px;
    word-break: break-word;
}

div.comment-box .limited-text {
    padding-left: 7px;
}

div.comment-box .limited-text button {
    color: #ffffff;
}

div.comment-box .limited-text button:hover {
    color: #F9F9F9;
}

div.comment-box::before {
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #559AF4;
    margin-left: -7px;
    content: " ";
}

.logs-and-comments-document-link {
    margin-right: 10px;
    float: right;
    font-size: 14px;
    padding:0;
    word-break: break-word;
}

svg.logs-and-comments-warning-icon {
    color: #F05B59;
    margin-right: 5px;
    height: 11px;
    width: 11px;
}

.lc-buttons {
    font-family: 'Nunito Sans';
    font-size: 12px;
    color: #FFFFFF;
    margin-left: 10px;
    align-self: flex-start;
}

.lc-buttons:hover {
    color: #FFFFFF
}

.lc-buttons.save-comment-btn {
    background-color: #3490DC;
}

.lc-buttons.add-file-btn {
    background-color: #6C757D;
}

.logs-and-comments .picker-list {
    padding-left: 30px;
}

.drag-n-drop-zone {
    cursor: pointer;
    margin-top: 10px;
    border-radius: 0.25rem;
    display: none;
    align-items: center;
    justify-content: center;
    outline: none;
    flex: 1 1 auto; 
    height: 114px; 
    width: 100%;
    font-size: 20px;
    background-color: #D3D2D2;
}

.drag-n-drop-zone.drag-over {
    display: flex;
}

.deletable-file > span:last-child {
    margin-left: 5px;
    opacity: 0;
    cursor: pointer;
}

.deletable-file:hover > span:last-child {
    opacity: 1;
}

.logs-and-comments .fa-rotate {
    font-size: 18px;
}