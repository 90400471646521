.string-collection-input-field .text-field {
    pointer-events: none;
}

.string-collection-input-field button {
    background: transparent !important;
    padding: 0 !important;
    border: none !important;
    width: 100% !important;
}

.string-collection-input-field .dropdown-menu {
    width: 100% !important;
    padding: 0.375rem 0.75rem !important;
}

.string-collection-input-field textarea {
    padding: 0 !important;
    border: none !important;
}