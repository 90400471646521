.credit-information-modal .modal-title {
    display: flex;
    align-items: center;
    column-gap: 16px;
    justify-content: space-between;
    width: 100%;
}

.credit-information-modal .modal-body {
    text-align: left;
    color: #707070;
    font-size: 14px;
}

.credit-information-modal .credit-information-details,
.credit-information-modal .credit-information-compare {
    display: flex;
    flex-direction: column;
    row-gap: 32px;
}

.credit-information-modal .secondary {
    opacity: 0.6;
}

.credit-information-modal .title {
    font-size: 16px;
    font-weight: bold;
    line-height: 2;
    display: block;
}

.credit-information-modal .personal-info {
    width: 50%;
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: baseline;
}

.credit-information-modal .personal-info > div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.credit-information-modal .personal-info > div:last-of-type {
    text-align: right;
}

.credit-information-modal .personal-info .credit-score {
    font-size: 32px;
}

.credit-information-modal .added {
    color: #42D253;
}

.credit-information-modal .removed {
    color: #F05B59;
}

.credit-information-modal .modified {
    display: flex;
    row-gap: 4px;
    flex-direction: column;
    align-items: center;
    width: fit-content;
}

.credit-information-modal table {
    width: fit-content;
}

.credit-information-modal table th,
.credit-information-modal table td {
    --width: calc((var(--bs-modal-width) - 2 * var(--bs-modal-padding)) / 8);
    /* we have a table with maximum 8 columns */
    width: var(--width);
}

.credit-information-modal table th.span-2,
.credit-information-modal table td.span-2 {
    /* we have a table with maximum 8 columns */
    width: calc(var(--width) * 2);
}

.credit-information-modal table thead {
    font-weight: 900;
    font-size: 12px;
    text-transform: uppercase;
    background-color: #f2f5f7;
    color: #9ea8b2;
}

.credit-information-modal table thead th {
    padding: 10px 8px;
}

.credit-information-modal table tbody tr:nth-of-type(2n) {
    background-color: #f9f9f9;
}

.credit-information-modal table tbody tr:nth-of-type(2n+1) {
    background-color: #ffffff;
}

.credit-information-modal table tbody tr td {
    padding: 10px 8px;
}

.credit-information-modal .print-credit-information {
    cursor: pointer;
    margin-left: 8px;
}

@media print {
    body {
        visibility: hidden;
    }

    .credit-information-modal {
        visibility: visible;
    }

    .credit-information-modal .print-credit-information,
    .credit-information-modal .compare-credit-information {
        visibility: hidden;
    }
}