.applications-pipeline {
    padding: 0;
}

.applications-pipeline .sidebar-buttons {
    position: fixed;    
    margin-left: calc(100% - 150px);
    display: flex;
    flex-direction: column;
    width: fit-content;
    height: fit-content;
    padding: 0;
}

.applications-pipeline .pipeline {
    padding: 0;
    max-height: calc(100vh - 200px);
}

.applications-pipeline .pipeline-filters {
    padding: 24px;
}

.applications-pipeline .pipeline-filters > *:not(.local-filters) {
    flex-grow: 1;
    max-width: 250px;
}

.applications-pipeline .local-filters {
    flex-grow: 1;
    justify-content: flex-end;
    align-items: center;
    display: flex;
    gap: 8px
}

.applications-pipeline .stages {
    margin-right: 64px;
    margin-left: 24px;
    padding-bottom: 12px;
    position: relative;
}

.applications-pipeline .stage  {
    min-width: 225px;
}

.applications-pipeline .stage .title {
    font-size: 16px;
    font-weight: bold;
    padding: 0 !important;
}

.applications-pipeline .stage .title .status {
    padding: 12px 16px;
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: relative;
}

.applications-pipeline .stage:not(.end-stage) .title .status::after {
    content: '';
    position: absolute;
    right: 0;
    width: 16px;
    height: 48px;
    background-color: white;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 100% 50%);
}

.applications-pipeline .application-item {
    --progress-width: 0%;
    --progress-color: transparent;
    border-radius: 4px;
    border: 1px solid #c6c6c6;
    position: relative;
}

.applications-pipeline .application-item:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: var(--progress-width);
    max-width: 100%;
    height: 3px;
    background-color: var(--progress-color);
    z-index: 1;
}

.applications-pipeline .application-item.product-0 {
    background: #2196f320;
}

.applications-pipeline .application-item.product-1 {
    background: #F8F8F8;
}

.applications-pipeline .application-item.product-2 {
    background: initial;
}

.applications-pipeline .application-item .header {
    display: flex;
    align-items: center;
    gap: 12px;
    justify-content: space-between;
    padding: 4px 8px 0 8px;
    color: #707070;
}

.applications-pipeline .application-item .header #applicant-tooltip-trigger {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.applications-pipeline .application-item .header a {
    color: #212529;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
}

.applications-pipeline .application-item .header .actions {
    cursor: pointer;
    flex-grow: 1;
    gap: 4px;
    display: flex;
    justify-content: flex-end;
}

.applications-pipeline .application-item .header .actions svg {
    width: 14px;
}

.applications-pipeline .application-item .content {
    padding: 4px 8px;
    display: flex;
    gap: 4px;
}

.applications-pipeline .application-item .details {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    gap: 4px;
    position: relative;
}

.applications-pipeline .application-item .details .detail {
    display: flex;
    gap: 8px;
    font-size: 14px;
    align-items: center;
    width: fit-content;
}

.applications-pipeline .application-item .details .detail svg {
    color: #707070;
    min-width: 18px;
}

.applications-pipeline .application-item .details .detail span {
    vertical-align: text-top;
    color: #212529;
    font-weight: 400;
}

.applications-pipeline .application-item .details .detail .follow-up-inline-input-field .value {
    flex-direction: row-reverse;
}

.applications-pipeline .application-item .details .multiselect.inline {
    padding: 0;
    gap: 8px;
}

.applications-pipeline .application-item .details .multiselect.inline .multiselect-item {
    border-radius: 15px;
    padding: 2px 10px 1px;
    margin: 0;
}

.applications-pipeline .application-item .inline-applicant-actions {
    display: flex;
    flex-direction: column;
    gap: 6px;
    font-size: 14px;
    color: #707070;
}

.applications-pipeline .application-item .inline-applicant-actions svg {
    cursor: pointer;
}