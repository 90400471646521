.design-tab-content {
    width: 50%;
}

.design-tab-content .content-length-info {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    font-size: 12px;
    color: #707070;
    margin-top: -12px;
}

.design-tab-content .content-length-info .error {
    color: #dc3545;
}

.design-tab-content > *:not(:last-child) {
    margin-bottom: 12px;
}

.html-editor-input-field-wrapper {
    display: flex;
    flex-direction: column;
}

.html-editor-input-field-wrapper .value{
    order: 2;
}

.html-editor-input-field-wrapper .description {
    order: 1;
}
