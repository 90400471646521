.marketing-list-view .top-panel {
    position: relative;
}

.marketing-list-view .content-panel {
    padding-top: 24px;
}

.marketing-list-name input {
    max-width: 450px;
    font-size: 20px !important;
    font-weight: bold;
    margin-bottom: 12px;
    border: none;
    padding: 0;
    border-radius: 0;
    color: #707070 !important;
}

.marketing-list-view .dropdown.actions {
    position: absolute;
    right: 0;
    top: 0;
}

.marketing-list-view #applicant-actions {
    display: none;
}

.customers-filter .readonly .substatuses-input-field-filter {
    display: flex;
    flex-wrap: wrap;
}

.customers-filter .readonly .substatuses-input-field-filter .status {
    margin-bottom: 4px;
}

.customers-filter .readonly {
    display: grid;
    grid-template-columns: repeat(6, minmax(100px, 1fr));
    align-items: flex-end;
    grid-gap: 12px;
}

.customers-filter .edit {
    display: grid;
    align-items: flex-start;
    grid-template-columns: repeat(6, minmax(100px, 1fr));
    grid-gap: 12px;
    position: relative;
}

.customers-filter .edit > * {
    max-width: 250px;
}

.customers-filter .edit .multiselect {
    border: 1px solid #ced4da;
    background-color: #ffffff;
}

.customers-filter .form-control {
    background-color: #ffffff;
}

.customers-filter .filter-active .form-control {
    border-color: #559AF4;
}

.customers-filter .edit .filter-active.multiselect {
    border: 1px solid #559AF4;
}

.customers-table {
    position: relative;
}

table.customers-table {
    font-size: 14px;
    text-align: left;
    font-family: 'Nunito Sans';
    white-space: nowrap;
    margin: 24px 0;
}

table.customers-table thead {
    font-weight: 900;
    font-size: 12px;
    text-transform: uppercase;
    background-color: #f2f5f7;
    color: #9ea8b2;
}

table.customers-table thead th {
    padding-left: 0;
    white-space: nowrap;
}

table.customers-table thead th:first-child {
    padding-left: 20px;
}

table.customers-table tbody tr:nth-of-type(2n) {
    background-color: #f9f9f9;
}

table.customers-table tbody tr:nth-of-type(2n+1) {
    background-color: #ffffff;
}

table.customers-table tbody tr:not(.not-interested):hover {
    background-color: #f2f5f7;
}

table.customers-table tbody tr.not-interested td,
table.customers-table tbody tr.not-interested td a {
    color: #afafaf;
    box-shadow: none;
}

table.customers-table tbody tr.not-interested td:not(:last-child),
table.customers-table tbody tr.not-interested td a {
    cursor: default;
    pointer-events: none;
}

table.customers-table tbody tr td {
    padding: 10px 8px 10px 0;
    vertical-align: middle;
}

table.customers-table tbody tr td a {
    color: var(--bs-body-color);
}

table.customers-table tbody tr td.follow-up-cell {
    width: 170px;
}

table.customers-table tbody tr td.follow-up-cell .value > span {
    font-weight: normal;
    color: inherit;
}

table.customers-table tbody tr td:first-child {
    padding-left: 20px;
}

table.customers-table tbody tr td.status-cell :not(:first-child) {
    margin-left: 3px;
}

table.customers-table ul {
    margin: 0;
}

table.customers-table .status {
    border-radius: 15px;
    padding: 2px 10px 1px;
    font-weight: 900;
    font-size: 12px;
}

table.customers-table .actions-cell div {
    display: flex;
    column-gap: 12px;
    color: #707070;
    cursor: pointer;
}

.marketing-list-actions {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    column-gap: 12px;
}

.marketing-list-actions button {
    min-width: 100px;
}