.html-editor-input-field .tox-tinymce {
    border: 1px solid #ced4da;
}

.html-editor-input-field.was-validated .tox-tinymce {
    border: 1px solid #dc3545;
}

.html-editor-input-field .html-preview {
    border: 1px solid #ced4da;
    background: #e9ecef;
    border-radius: 0.375rem;
    overflow: auto;
}