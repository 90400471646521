.import-bank-data {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
}

.import-bank-data .import-bank-data-scenario {
    display: grid;
    grid-template-columns: 50% 50%;
    max-width: 400px;
}

.import-bank-data .aggregate-bank-data {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    align-items: start;
    gap: 8px;
}

.import-bank-data table {
    text-align: left;
}

.import-bank-data table.aggregated-bank-data tr {
    display: flex;
}

.import-bank-data table.aggregated-bank-data th,
.import-bank-data table.aggregated-bank-data td {
    width: 0;
    flex-grow: 1;
}

.import-bank-data table thead {
    font-weight: 900;
    font-size: 12px;
    text-transform: uppercase;
    background-color: #f2f5f7;
    color: #9ea8b2;
}

.import-bank-data table thead th {
    padding: 10px 8px;
}

.import-bank-data table tbody tr:nth-of-type(2n) {
    background-color: #f9f9f9;
}

.import-bank-data-information-modal table tbody tr:nth-of-type(2n+1) {
    background-color: #ffffff;
}

.import-bank-data table tbody tr td {
    padding: 10px 8px;
}

.import-bank-data table .status {
    border-radius: 15px;
    padding: 2px 10px 1px;
    font-weight: 900;
    font-size: 12px;
    width: fit-content;
}