.property-information {
    text-align: left;
    color: #707070;
    font-size: 14px;
    margin-right: 250px;
    margin-left: auto;
    width: 150px;
    position: relative;
    min-height: 80px;
}

.property-information .details {
    text-align: left;
    color: #707070;
    font-size: 14px;
    position: absolute;
    top: 31px;
}

.property-information .details .value {
    margin-bottom: 5px;
}


.property-information .property-title {
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
}

.pi-external-link {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
}

span.missing-value {
    font-style: italic;
    opacity: 0.8;
}