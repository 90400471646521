.dropdown-menu {
    --bs-dropdown-link-hover-bg: #f8f9fa;
}
.dropdown.show .dropdown-arrow-down,
.dropdown.show .dropdown-arrow-left,
.dropdown.show .dropdown-arrow-right {
    display: block;
}

.dropdown-arrow-down {
    position: absolute;
    display: none;
    width: 1rem;
    height: .5rem;
    margin: 0 .3rem;
    z-index: 1001;
    left: 7px;
}

.dropdown-arrow-down::before,
.dropdown-arrow-down::after {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
    z-index: 1001;
}

.dropdown-arrow-down::before {
    top: -6px;
    border-bottom-color: rgba(0, 0, 0, 0.25);
    border-width: 0 .5rem .5rem .5rem;
}

.dropdown-arrow-down::after {
    top: -5px;
    border-bottom-color: #fff;
    border-width: 0 .5rem .5rem .5rem;
}

.dropdown-arrow-right,
.dropdown-arrow-left {
    position: absolute;
    display: none;
    width: .5rem;
    height: 1rem;
    margin: .3rem 0;
    z-index: 1001;
}

.dropdown-arrow-left {
    right: calc((.5rem + 1px) * -1);
}

.dropdown-arrow-right {
    left: calc((.5rem + 1px) * -1);  
}

.dropdown-arrow-right::before,
.dropdown-arrow-right::after,
.dropdown-arrow-left::before,
.dropdown-arrow-left::after {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
    z-index: 1001;
}

.dropdown-arrow-left::before {
    right: 1px;
    border-left-color: black;
    border-width: .5rem 0 .5rem .5rem;
}

.dropdown-arrow-left::after {
    right: 1px;
    border-left-color: white;
    border-width: .5rem 0 .5rem .5rem;
}

.dropdown-arrow-right::before {
    left: 1px;
    border-right-color: black;
    border-width: .5rem .5rem .5rem 0;
}

.dropdown-arrow-right::after {
    left: 1px;
    border-right-color: white;
    border-width: .5rem .5rem .5rem 0;
}

.dropdown-submenu.dropright .dropdown-menu,
.dropdown-submenu.dropleft .dropdown-menu {
    /* overrides bootstrap styles */
    top: -9px !important;
}

.dropdown-no-indicator.dropright .dropdown-toggle::before,
.dropdown-no-indicator.dropleft .dropdown-toggle::before {
    display: none;
}

.dropdown-no-indicator.dropdown-submenu.dropright,
.dropdown-no-indicator.dropdown-submenu.dropleft {
    padding: 0.25rem 1.5rem;
    position: relative;
}

.dropdown-no-indicator.dropdown-submenu.dropright:hover,
.dropdown-no-indicator.dropdown-submenu.dropleft:hover {
    background-color: #f8f9fa;
    color: #16181b;
    cursor: pointer;
}

/* offset dropdown that's inside other dropdown, override inline style */
.dropdown-submenu.dropleft .dropdown-menu {
    right: calc(100% + .5rem);
}

.dropdown-submenu.dropright .dropdown-menu {
    left: calc(100% + .5rem);
}

.dropdown-menu {
    font-family: "Nunito Sans";
    font-size: 14px;
}

a.dropdown-item,
a.dropdown-item:hover {
    text-decoration: none;
}