.property {
    padding: 12px;
    color: #707070;
}

.property-type {
    display: inline;
    margin-right: 10px;
}

.property .options {
    float: right;
}

.property .options span {
    margin-left: 5px;
}

.property-data {
    display: flex;
    margin-top: 10px;
}


.property-data .value {
    max-width: 250px;
}

.property .value+.description, .property .value:last-child {
    margin-bottom: 12px;
}


table.property-owners {
    font-size: 14px;
    text-align: left;
    font-family: 'Nunito Sans';
    margin:0;
    white-space: nowrap;
    width: 100%;
}

table.property-owners thead {
    font-weight: 900;
    font-size: 12px;
    text-transform: uppercase;
    background-color: #f2f5f7;
    color: #9ea8b2;
}

table.property-owners thead th {
    padding: 10px 8px;
    white-space: nowrap;
}

table.property-owners thead th:not(:first-child) {
    width: 100px;
}

table.property-owners tbody tr:nth-of-type(2n) {
    background-color: #f9f9f9;
}

table.property-owners tbody tr:nth-of-type(2n+1) {
    background-color: #ffffff;
}

table.property-owners tbody tr td {
    padding: 10px 8px;
}

.accuracy-badge {
    display: inline-block;
    padding: 3px 5px;
    border-radius: 8px;
    font-size: 10px;
    line-height: 10px;
    font-weight: 700;
    vertical-align: super;
    color: #ffffff;
}

.accuracy-badge.accuracy-1, .accuracy-badge.accuracy-2 {
    background-color: #42D253;
}

.accuracy-badge.accuracy-3 {
    background-color: #559AF4;
}

.accuracy-badge.accuracy-4, .accuracy-badge.accuracy-5 {
    background-color: #FF9900;
}

.accuracy-badge.accuracy-6, .accuracy-badge.accuracy-7 {
    background-color: #F05B59;
}