.main-content .applicants-view {
    margin: 0;
    margin-top: 19px;
    margin-bottom: 14px;
    padding-top: 0;
    min-width: 1300px;
}

.main-content>div:last-child {
    margin-bottom: 14px;
}

.filter {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    float: left;
    clear: both;
    width: calc(100% - 16px);
}

.filter > div {
    margin: 0 5px;
    max-width: 210px;
}

.filter > div:first-child {
    margin-left: 0;
    max-width: 250px;
}

.filter > div:last-child {
    margin-right: 0;
    max-width: 250px;
}

.filter.limited-view > div {
    max-width: 300px;
}

.filter .react-autosuggest__container {
    max-width: 250px;
    flex: 1;
}

.filter .generic-dropdown {
    flex: 1;
}

.filter .generic-dropdown > button {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.filter .react-autosuggest__input {
    max-width: unset;
    min-width: unset;
}

.filter-popover {
    min-width: 370px;
}

.filter-popover .col-6:nth-of-type(2n+1) {
    padding-right:0;
}

.filter-popover .popover-body {
    padding-bottom: 0;
}

.filter-buttons {
    width: 100%;
    background-color: #f2f5f7;
    padding: 10px;
    margin-left: 4px;
    margin-right: 4px;
    margin-top: 10px;
}

.filter-buttons .btn {
    font-size: 12px;
    font-family: 'Nunito Sans';
}

.filter-indicator {
    color: #559AF4;
    font-size: 8px;
    position: absolute;
    top:-2px;
    right: -3px;
}

.filter-popover .form-control {
    background-color: #ffffff !important;
}

.filter-popover .multiselect {
    border: 1px solid #ced4da;
    background-color: #ffffff;
}

.filter-active .form-control {
    border-left-color: #559AF4;
    border-top-color: #559AF4;
    border-bottom-color: #559AF4;
}

.filter-active.multiselect {
    border: 1px solid #559AF4;
}

.generic-dropdown .btn.dropdown-toggle.filter-active, .filter-active .input-group-text {
    border-color: #559AF4;
}

.advertisement-item, .application-source-item {
    font-weight: normal;
}

.table-container {
    border-top: 1px solid #C6C6C6;
    /* to do decide padding top and bottom  */
    padding-left: 50px;
    padding-right: 50px;
}

.applicants-table {
    white-space: nowrap;
}

table.applicants-table {
    font-size: 14px;
    text-align: left;
    font-family: 'Nunito Sans';
    margin:0;
}

table.applicants-table thead {
    font-weight: 900;
    font-size: 12px;
    text-transform: uppercase;
    background-color: #f2f5f7;
    color: #9ea8b2;
}

table.applicants-table thead th {
    padding-left: 0;
    white-space: nowrap;
}

table.applicants-table thead th:first-child {
    padding-left: 20px;
}

table.applicants-table tbody tr:nth-of-type(2n) {
    background-color: #f9f9f9;
}

table.applicants-table tbody tr:nth-of-type(2n+1) {
    background-color: #ffffff;
}

table.applicants-table tbody tr.applicant-has-debt-collections {
    background-color: #edc1cb;
}

table.applicants-table tbody tr:hover {
    background-color: #f2f5f7;
}

table.applicants-table tbody tr td {
    padding: 0;
    padding-top: 10px;
    padding-bottom: 10px;
}

.applicants-table .status, .substatuses .status {
    border-radius: 15px;
    padding: 2px 10px 1px;
    color: white;
    font-weight: 900;
    font-size: 12px;
}

.status button {
    color: #ffffff;
}

.status.status-gray button {
    color: #9EA8B2;
}

.status.status-gray {
    color: #9EA8B2;
    background-color: rgba(238, 238, 238, 1);
}

.status.status-gray.not-selected {
    color: #9EA8B2;
    background-color: unset;
}

.status.status-gray.not-selected:hover {
    color: #9EA8B2;
    background-color: rgba(238, 238, 238, 0.5);
}

.status.status-blue {
    color: #FFFFFF;
    background-color: rgba(85, 154, 250, 1);
}

.status.status-blue.not-selected {
    color: #559AF4;
    background-color: unset;
}

.status.status-blue.not-selected:hover {
    color: #FFFFFF;
    background-color: rgba(85, 154, 250, 0.5);
}

.status.status-orange {
    color: #ffffff;
    background-color: rgba(255, 153, 0, 1);
}

.status.status-orange.not-selected {
    color: #FF9900;
    background-color: unset;
}

.status.status-orange.not-selected:hover {
    color: #ffffff;
    background-color: rgba(255, 153, 0, 0.5);
}

.status.status-turquoise {
    color: #ffffff;
    background-color: rgba(91, 192, 222, 1);
}

.status.status-turquoise.not-selected {
    color: #5BC0DE;
    background-color: unset;
}

.status.status-turquoise.not-selected:hover {
    color: #ffffff;
    background-color: rgba(91, 192, 222, 0.5);
}

.status.status-green {
    color: #ffffff;
    background-color: rgba(66, 210, 83, 1);
}

.status.status-green.not-selected {
    color: #42D253;
    background-color: unset;
}

.status.status-green.not-selected:hover {
    color: #ffffff;
    background-color: rgba(66, 210, 83, 0.5);
}

.status.status-red {
    color: #ffffff;
    background-color: rgba(240, 91, 89, 1);
}

.status.status-red.not-selected {
    color: #F05B59;
    background-color: unset;
}

.status.status-red.not-selected:hover {
    color: #ffffff;
    background-color: rgba(240, 91, 89, 0.5);
}

.status.status-lightgoldenrodyellow {
    color: #9EA8B2;
    background-color: rgba(250, 250, 210, 1);
}

.status.status-lightgoldenrodyellow.not-selected {
    color: #9EA8B2;
    background-color: unset;
}

.status.status-lightgoldenrodyellow.not-selected:hover {
    color: #9EA8B2;
    background-color: rgba(250, 250, 210, 0.5);
}

.status.status-moccasin {
    color: #9EA8B2;
    background-color: #FFE4B5;
}

table.applicants-table tbody tr td:first-child {
    padding-left: 20px;
}

table.applicants-table tbody tr td.status-cell :not(:first-child){
    margin-left: 3px;
}

table.applicants-table ul {
    margin: 0;
}

table.applicants-table td.follow-up-cell .value > span {
    font-weight: normal;
    color: inherit;
}

table.applicants-table td.follow-up-cell .fa-bell-slash {
    display: none;
}

table.applicants-table th:last-child  {
    pointer-events: none;
}

table.applicants-table th:last-child > span:last-child {
    display: none;
}

table.applicants-table .actions-cell {
    display: flex;
    align-items: center;
    column-gap: 12px;
    color: #707070 !important;
}

hr.table-separator {
    margin-top: 11px;
    border: none;
}

.applicants-view .substatuses {
    text-align: left;
    padding-left: 50px;
    padding-right: 50px;
}

.applicant-tooltip-overview .substatuses .status,
.applicants-view .substatuses .status {
    margin-top: 15px;
    margin-left: 5px;
    margin-right: 5px;
    float: left;
}

.applicant-actions {
    padding: 8px 0 !important;
    position: relative;
}

.applicant-actions .dropdown-item > *:first-child {
    min-width: 16px;
}

.applicant-actions .dropdown-item {
    padding: 4px 8px;
    display: flex;
    align-items: center;
    column-gap: 8px;
    text-transform: none;
}

.applicant-actions .dropdown-item:hover {
    background: #f8f9fa;
}

.applicant-tooltip {
    margin-left: 12px;
    max-width: min-content;
    min-height: 150px;
}

.applicant-tooltip .popover-body {
    display: grid;
    grid-template-columns: min-content;
    align-items: flex-start;
    gap: 15px;
}

.applicant-tooltip .popover-body > * {
    min-width: max-content;
    height: 100%;
}

.applicant-tooltip .applicant-tooltip-overview {
    grid-column: span 2;
}

.applicant-tooltip-overview .substatuses {
    overflow: hidden;
}

.applicant-tooltip-overview .loan-picker {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: flex-start;
}

.applicant-tooltip-overview .loan-picker button.scroll-button {
    display: none;
}

.applicant-tooltip .logs-and-comments-table {
    margin-top: 0;
    height: auto;
    max-width: 700px;
}

.applicant-tooltip .transaction-overview-row {
    height: 45px;
}

.applicant-tooltip .transaction-overview-row:not(:first-child) {
    border-top: 1px solid rgba(198, 198, 198, 0.5);
}

.applicant-tooltip .transaction-overview-row td {
    padding: 0 8px;
}

.applicant-tooltip .transaction-overview-row td:first-child {
    font-weight: 700;
}

.applicant-tooltip .bank-report-history {
    border: none;
    margin-top: 0;
    height: 100%;
    max-height: fit-content;
    min-height: 45px;
    max-width: 700px;
}