.topbar {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.topbar-buttons {
    height: 50px;
    align-items: center;
    display: flex;
    padding-left: 23px;
}

.topbar-buttons > span {
    padding: 3px 12px;
}

.topbar-buttons .logo {
    height: 35px;
    margin-left: 10px;
}

.user-info {
    height: 50px;
    align-items: center;
    justify-content: flex-end;
    display: flex;
    column-gap: 12px;
    padding-right: 35px;
}

.sidebar {
    --sidebar-width: 209px;
    position: fixed;
    top: 0;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    width: var(--sidebar-width);
    display: flex;
    flex-direction: column;
    padding-bottom: 5rem;
    color: #fff;
    z-index: 2;
}

.sidebar-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
}

.sidebar-title .close-sidebar {
    border: none;
    background: transparent;
    color: white;
}

.sidebar.left {
    left: 0;
    transition: left 0.25s ease;
}

.sidebar.left.hidden {
    left: calc(-1 * var(--sidebar-width));
    transition: left 0.25s ease;
}

.sidebar.right {
    right: 0;
    transition: right 0.25s ease;
}

.sidebar.right.hidden {
    right: calc(-1 * var(--sidebar-width));
    transition: right 0.25s ease;
}

.sidebar-bg {
    background-color: #000;
}

.user-name {
    margin-top: 10px;
    font-family: 'Varela Round';
    font-size: 12px;
    text-transform: uppercase;
}

.sidebar > div.user-info > div {
    max-width: 100%;
    -webkit-flex: 0 0 100%;
    flex: 0 0 100%;
    display: flex;
    justify-content: center;
}

.nav-links-container {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    margin-top: calc(5rem + 25px);
}

.nav-links-container > a {
    font-family: 'Varela Round';
    font-size: 16px;
    padding: 18.5px 0;
    opacity: 0.6;
    -webkit-transition: color 300ms linear;
    -moz-transition: color 300ms linear;
    -o-transition: color 300ms linear;
    -ms-transition: color 300ms linear;
    transition: color 300ms linear;
    cursor: pointer;
    text-decoration: none;
}

.nav-links-container > a:hover,
.nav-links-container > a.active-route {
    opacity: 1;
    text-decoration: none;
    -webkit-transition: color 300ms linear;
    -moz-transition: color 300ms linear;
    -o-transition: color 300ms linear;
    -ms-transition: color 300ms linear;
    transition: color 300ms linear;
}

.nav-links-container > div {
    margin: 23px 96px 23px 0;
}

.top-panel {
    border-bottom: 1px solid #c6c6c6;
}