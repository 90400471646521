.bookmarks .dropdown-toggle {
    color: #707070 !important;
}

.bookmarks .dropdown-menu {
    transform: translate(10px, 28px) !important;
    min-width: 250px;
}

.bookmarks .dropdown-arrow-down {
    margin: 0 -8px;
}

.bookmarks .dropdown-header {
    font-weight: bold;
    color: black;
}

.bookmarks .dropdown-item,
.bookmarks .dropdown-header {
    display: flex;
    align-items: center;
    column-gap: 16px;
    justify-content: space-between;
}

.bookmarks .dropdown-item.active {
    background: #f2f5f7;
    color: black;
}

.bookmarks .add-bookmark {
    padding-top: 0;
    padding-bottom: 0;
}

.bookmarks .dropdown-divider:first-child {
    display: none;
}

.bookmarks .add-bookmark .value {
    flex-grow: 1;
}

.bookmarks .dropdown-item.active ~ .add-bookmark-divider,
.bookmarks .dropdown-item.active ~ .add-bookmark {
    display: none;
}

.bookmarks .add-bookmark-button {
    color: #42D253;
    cursor: pointer;
    min-width: 16px;
}

.bookmarks .delete-bookmark-button {
    color: #F05B59;
    cursor: pointer;
    min-width: 16px;
}

.bookmarks .add-bookmark-button.disabled {
    cursor: not-allowed;
    color: #707070a6;
}