.react-datepicker,
.react-datepicker__day {
    color: #212529;
    font-weight: normal;
    font-family: 'Nunito Sans';
}

.react-datepicker__day--selected,
.react-datepicker__time-list-item--selected,
.react-datepicker__day--keyboard-selected {
    background-color: #3490dc !important;
    color: white;
}

.react-datepicker__header,
.react-datepicker__current-month,
.react-datepicker__day-name,
.react-datepicker-time__header {
    background-color: #f2f5f7;
    color: #9ea8b2;
    text-transform: uppercase;
    font-size: 12px;
}

.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker {
    font-size: 14px;
    border-top-right-radius: 0;
}

.react-datepicker:not(.show-today) {
    border-bottom-right-radius: 0;
}

.react-datepicker__triangle {
    top: 1px;
}

.react-datepicker__navigation-icon::before {
    width: 10px;
    height: 10px;
    top: calc(50% - 5px);
}

.react-datepicker__navigation--next {
    right: 2px !important;
}

.show-today .react-datepicker__month-container {
    margin-bottom: 1px;
}

.react-datepicker__today-button {
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
}

.react-datepicker__time-container,
.react-datepicker__time-container--with-today-button {
    border: 1px solid #aeaeae;
    border-radius: 0 0.3rem 0.3rem 0;
    top: -1px;
    position: absolute;
    right: -86px;
    box-sizing: content-box;
    border-left: none;
    z-index: -1;
}

.react-datepicker__time-list-item--disabled {
    display: none;
}

.react-datepicker-popper {
    transform: none !important;
}