.substatuses-input-field.readonly {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
}

.substatuses-input-field.readonly .status {
    font-size: 12px;
    font-weight: bold;
    padding: 0 5px;
    border-radius: 0.25em;
}

.substatuses-input-field.readonly.only-important:not(:has(.important)) {
    display: none;
}

.substatuses-input-field.only-important .status:not(.important) {
    display: none;
}